<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button @click="printAll()" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Client</th>
          <th scope="col">Montant TTC</th>
          <th scope="col">Montant HT</th>
          <th scope="col">Date</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(invoice, index) in invoices" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ invoice.reference }}
          </td>
          <td>
            {{ invoice.customerFullName }}
          </td>

          <td>
            <span>
              {{ parseFloat(invoice.totalPriceTTC).toFixed(2) }}
            </span>
          </td>

          <td>
            {{ parseFloat(invoice.totalPriceHT).toFixed(2) }}
          </td>

          <td>{{ invoice.date }}</td>

          <td class="d-flex">
            <button @click="printInvoice(invoice.reference)" class="btn">
              <i class="bi bi-printer"></i>
            </button>
            <button @click="remove(invoice)" class="btn">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("invoice", {
      invoices: "getInvoices",
    }),
    // imageUrl() {
    //   return (invoice) => `${invoice.image}`
    // },
  },
  mounted() {
    this.$store.dispatch("invoice/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("invoice/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("invoice/getAll");
    },

    async sort(value) {
      return await this.$store.commit("invoice/sort", value);
    },

    async remove(data) {
      let invoice = data;
      await this.$confirm({
        message: "Vous voulez supprimer la facture   " + invoice.reference,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("invoice/destroy", invoice.reference);
          }
        },
      });
    },

    async printAll() {
      return await this.$store.dispatch("invoice/printAll");
    },

    async printInvoice(reference) {
      return await this.$store.dispatch("invoice/print", reference);
    },
  },
};
</script>
